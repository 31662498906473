<template>
  <v-card>
    <v-card-title>{{$t('m.manageMail.title')}}</v-card-title>
    <v-card-text>
      <h3>{{$t('m.manageMail.icsTitle')}}</h3>
      <v-radio-group v-model="ics_">
        <v-radio :label="$t('m.manageMail.stop')" value="stop"></v-radio>
        <v-radio :label="$t('m.manageMail.daily')" value="daily"></v-radio>
        <v-radio :label="$t('m.manageMail.weekly')" value="weekly"></v-radio>
        <v-radio :label="$t('m.manageMail.monthly')" value="monthly"></v-radio>
      </v-radio-group>
      <h3>{{$t('m.manageMail.csvTitle')}}</h3>
      <v-radio-group v-model="csv_">
        <v-radio :label="$t('m.manageMail.stop')" value="stop"></v-radio>
        <v-radio :label="$t('m.manageMail.daily')" value="daily"></v-radio>
        <v-radio :label="$t('m.manageMail.weekly')" value="weekly"></v-radio>
        <v-radio :label="$t('m.manageMail.monthly')" value="monthly"></v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'manage-mail',
  props: {
    ics: {
      type: String,
      required: false,
      default: 'daily'
    },
    csv: {
      type: String,
      required: false,
      default: 'daily'
    }
  },
  computed: {
    ics_: {
      get () {
        return this.ics
      },
      set (value) {
        this.$emit('update', { ics: value, csv: this.csv })
      }
    },
    csv_: {
      get () {
        return this.csv
      },
      set (value) {
        this.$emit('update', { ics: this.ics, csv: value })
      }
    }
  }
}
</script>
