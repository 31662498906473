<template>
  <v-container fluid>
    <v-progress-circular indeterminate v-if="!loaded" />
    <manage-mail :ics="ics" :csv="csv" @update="onUpdate" v-else></manage-mail>
  </v-container>
</template>

<script>
import { getMailSubscription, updateMailSubscription } from '../helpers/firebase-functions'
import manageMail from '@/components/manageMail/ManageMail'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    manageMail
  },
  data: () => ({
    loaded: false,
    ownerId: '',
    scenarioId: '',
    ics: '',
    csv: ''
  }),
  methods: {
    async onUpdate ({ ics, csv }) {
      this.ics = ics
      this.csv = csv
      await updateMailSubscription({ ownerId: this.ownerId, scenarioId: this.scenarioId, ics, csv })
    }
  },
  async created () {
    this.ownerId = this.$route.params.ownerId
    this.scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    const { data: { ics, csv } } = await getMailSubscription({ ownerId: this.ownerId, scenarioId: this.scenarioId })
    this.loaded = true
    this.ics = ics
    this.csv = csv
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_MANAGE_MAIL)
  }
}
</script>
